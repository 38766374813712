<template>
  <div>
    <div class="chat">
      <div style="padding: 10px;">
        <b-avatar :src="iconPathChatPartner" size="2em" style="background-color:white;"></b-avatar>
        {{ name_chat_partner }}
        <span style="margin-left:10px; margin-right:10px;">|</span>
        <b-avatar :src='require("@/assets/pirate_bay/chat/you_icon.png")' size="2em" style="background-color: white; color:black"></b-avatar>
        {{ $t('PirateBay.ChatInterface.You') }}
      </div>

      <div class="initial-message" v-if="responses.length == 0">
        {{ $t('PirateBay.ChatInterface.initialMessage') }}
      </div>

      <div v-else>
        <br>
      </div>

      <div v-for="response in responses" :key="response.id">
        <div class="message message-user">
          <b-row>
            <b-col cols="1" class="text-center">
              <b-avatar :src='require("@/assets/pirate_bay/chat/you_icon.png")' size="2em" style="background-color: white; color:black"></b-avatar>
            </b-col>
            <b-col cols="10">
              <div>{{ response.user }}</div>
            </b-col>
          </b-row>
        </div>
        <div class="message">
          <b-row>
            <b-col cols="1" class="text-center">
              <b-avatar :src="iconPathChatPartner" size="2em" style="background-color:white;"></b-avatar>
            </b-col>
            <b-col cols="10">
              <div style="white-space: pre-line;">{{ response.gpt }}</div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="spinner" v-if="showSpinner"><b-spinner variant="secondary"></b-spinner></div>
    </div>

    <br>
    <b-input-group>
      <b-form-input v-model="message" :placeholder="sendMessageText" @keyup.enter="sendMessage" />
      <b-input-group-append>
        <b-button @click="sendMessage" variant="primary">{{sendButtonText}}</b-button>
      </b-input-group-append>
    </b-input-group>
    
    <b-button size="sm" @click="resetChat" style="margin-top: 10px;">{{resetChatText}}</b-button>
  </div>
</template>

<script>
export default {
  props: {
    original_prompt: String,
    save_context: Boolean,
    name_chat_partner: String,
    icon_path_chat_partner: String,

  },
  data() {
    return {
      message: "",
      messages: [{
        role: "system",
        content: this.original_prompt
      }],
      responses: [],
      messagesSent: 0,
      maxSentMessages: 30,
      maxSavedMessages: 2,
      maxTokensPerMessage: 5000,
      showSpinner: false,
    };
  },

  computed: {
    iconPathChatPartner() {
      // Make sure the path is not empty
      if (this.icon_path_chat_partner) {
        return require('@/assets/' + this.icon_path_chat_partner)
      } else {
        return null
      }
    },
    sendMessageText() {
      if (this.$i18n.locale === 'en') {
        return 'Send a message';
      } else if (this.$i18n.locale === 'de') {
        return 'Sende eine Nachricht';
      } else {
        return null
      }
    },
    sendButtonText() {
      if (this.$i18n.locale === 'en') {
        return 'Send';
      } else if (this.$i18n.locale === 'de') {
        return 'Senden';
      } else {
        return null
      }
    },
    resetChatText() {
      if (this.$i18n.locale === 'en') {
        return 'Reset chat';
      } else if (this.$i18n.locale === 'de') {
        return 'Chat zurücksetzen';
      } else {
        return null
      }
    }
  },

  methods: {
    countTokens(message) {
      // Logic to count tokens (customize this according to your token definition)
      // This example splits the message by space to count tokens
      const tokens = message.trim().split(/\s+/);
      return tokens.length;
    },

    async sendMessage() {
      this.showSpinner = true;
      try {
        if (this.messagesSent < this.maxSentMessages) {
          const tokenCount = this.countTokens(this.message);

          if (tokenCount <= this.maxTokensPerMessage) {

            if (this.save_context == true) {
              // Keep only the last 10 messages
              if (this.messages.length > this.maxSavedMessages) {
                this.messages.shift(); // Remove the oldest message
              }
              this.messages.push(
                {
                  role: "system",
                  content: this.original_prompt
                },
                {
                  role: "user",
                  content: this.message
                }
              )
            }

            else {
              this.messages = [
                {
                  role: "system",
                  content: this.original_prompt
                },
                {
                  role: "user",
                  content: this.message
                }
              ]
            }

            const response = await this.$axios.post(
              "https://api.openai.com/v1/chat/completions",
              {
                model: "gpt-3.5-turbo", // or another model if preferred
                messages: this.messages
              },
              {
                headers: {
                  "Authorization": "Bearer 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", // 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
                  "Content-Type": "application/json"
                }
              }
            );

            this.responses.push({
              id: Date.now(), // Assign a unique ID to each response
              user: this.message,
              gpt: response.data.choices[0].message.content
            });
            console.log(response.data.choices[0].message.content)

            if (this.save_context == true) {
              this.messages.push({
                role: "assistant",
                content: response.data.choices[0].message.content
              })
            }

            this.message = ""; // Reset the input field
            this.showSpinner = false;

            this.messagesSent++; // Increment the message counter
          } else {
            alert(`You've exceeded the maximum tokens (${this.maxTokensPerMessage}) allowed per message.`);
          }

        } else {
          alert('You have reached the maximum number of messages.')
        }

      } catch (error) {
        console.error("Error sending message:", error);
        console.log("Did you set the openai key?")
        this.showSpinner = false;
      }
    },

    resetChat() {
      this.messages = [{
        role: "system",
        content: this.original_prompt
      }]
      this.responses = []
    }
  }
};
</script>

<style scoped>
.chat {
  max-height: 500px;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
}

.initial-message {
  padding-top: 7px;
  padding-left: 10px;
  color: grey;
  font-style: italic;
}

.spinner {
  padding-top: 7px;
  padding-left: 10px;
}

.message {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
}

.message-user {
  background-color: WhiteSmoke;
  border-top: solid lightgrey 2px;
  border-bottom: solid lightgrey 2px;
}
</style>
