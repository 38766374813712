<template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group :label="solutionLabel" label-for="input-solution">
        <b-form-textarea id="input-solution" :rows="5" v-model="entry" :placeholder="$t('SolutionCode.yourAnswerPlaceholder')"
          required></b-form-textarea>
      </b-form-group>

      <span><b-button type="submit" style="background-color:Olive; border:none">{{ $t('SolutionCode.submitButton')
      }}</b-button>&nbsp;&nbsp;<b-spinner small variant="secondary" v-if="showSpinner"></b-spinner></span>
    </b-form>

    <br>

    <div v-if="show_first_letters_result">
      <span>Hidden message: <b>{{ first_letters }}</b>&nbsp;</span>
      <b-icon v-if="!first_letters_correct" icon="x-circle-fill" variant="danger"></b-icon>
      <b-icon v-if="first_letters_correct" icon="check-circle-fill" variant="success"></b-icon>
    </div>

    <div v-if="show_content_result">
      <span>{{$t("PirateBay.Chapter2.Solution.contentQuestion")}}&nbsp;</span>
      <b-icon v-if="!story_content_is_correct" icon="x-circle-fill" variant="danger"></b-icon>
      <b-icon v-if="story_content_is_correct" icon="check-circle-fill" variant="success"></b-icon>
    </div>

    <br>

    <b-alert v-if="solution_is_wrong" show variant="warning">{{ $t('SolutionCode.wrongSolutionAlert') }}</b-alert>
    <b-alert v-if="solution_is_correct" show variant="success">{{ $t('SolutionCode.correctSolutionAlert') }}</b-alert>
  </div>
</template>
  
<script>
export default {
  props: {
    solution: String,
  },
  data() {
    return {
      entry: "",
      solution_is_correct: false,
      first_letters: "",
      first_letters_correct: false,
      show_first_letters_result: false,
      solution_is_wrong: false,
      story_content_is_correct: false,
      show_content_result: false,
      responses: [],
      content_threshold: 0.8,
      showSpinner: false,
    };
  },

  computed: {
    solutionLabel() {
      return this.$t("SolutionCode.solutionLabel");
    }
  },

  methods: {
    async sendMessage() {
      try {
        const response = await this.$axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-4",
            messages: [
              {
                role: "system",
                content: this.$t("PirateBay.Chapter2.Solution.systemPrompt") //"Please only answer with a decimal number. Give me certainty score on whether the following story is about mussels: "
              },
              {
                role: "user",
                content: this.entry
              }
            ]
          },
          {
            headers: {
              "Authorization": "Bearer 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",
              "Content-Type": "application/json"
            }
          }
        );

        this.responses.push({
          id: Date.now(), // Assign a unique ID to each response
          user: this.message,
          gpt: response.data.choices[0].message.content
        });

        this.message = ""; // Reset the input field

        return response.data.choices[0].message.content

      } catch (error) {
        console.error("Error sending message:", error);
        console.log("Did you set the openai key?")
      }
    },

    extractFirstLetters() {
      const sentences = this.entry.replace(/\n/g, " ").split(/[.!?]/ ); // Split sentences based on punctiation marks and space
      const firstLetters = sentences.map(sentence => sentence.trim().charAt(0)) // Extract the first letter of each sentence
      return firstLetters.join('').toLocaleLowerCase(); // Combine all first letters into a single string
    },

    onSubmit(event) {
      // Show spinner
      this.showSpinner = true;

      event.preventDefault()

      this.sendMessage().then((response_content) => {

        // Reset displays
        this.show_first_letters_result = false
        this.show_content_result = false

        // Check if first letters match
        this.first_letters = this.extractFirstLetters()
        console.log("first_letters:", this.first_letters)
        this.first_letters_correct = this.first_letters.toLowerCase() == this.$t("PirateBay.Chapter2.Solution.firstLetters")

        // Check if story content is correct (ask ChatGPT to provide a certainty score)
        this.story_content_is_correct = false
        if (response_content >= this.content_threshold) {
          this.story_content_is_correct = true;
        }

        // Display results
        this.show_first_letters_result = true
        this.show_content_result = true

        if (this.first_letters_correct && (this.story_content_is_correct)) {
          this.solution_is_correct = true;
          this.solution_is_wrong = false;

          this.$emit("solution-correct", true);
        } else {
          this.solution_is_correct = false;
          this.solution_is_wrong = true;
        }

        // Hide spinner
        this.showSpinner = false;
        
        // Logging some results        
        console.log(response_content)
        console.log(this.solution_is_correct);
        console.log(this.story_content_is_correct);
      });
    }
  },
}
</script>