<template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group :label="solutionLabel" label-for="input-solution">
        <b-form-textarea id="input-solution" :rows="5" v-model="entry" :placeholder="$t('SolutionCode.yourAnswerPlaceholder')"
          required></b-form-textarea>
      </b-form-group>

      <span><b-button type="submit" style="background-color:Olive; border:none">{{ $t('SolutionCode.submitButton')
      }}</b-button>&nbsp;&nbsp;<b-spinner small variant="secondary" v-if="showSpinner"></b-spinner></span>
    </b-form>

    <br>

    <p v-if="showCheckHeader"><b>{{ $t('PirateBay.Chapter3.checkSolution.header') }}</b></p>

    <div v-for="(pair, index) in processedQuestions" :key="pair.id">
      <div>
        <strong>{{ $t('PirateBay.Chapter3.checkSolution.question') }} {{ index + 1 }}:</strong> {{ pair.question }}
      </div>
      <div>
        <strong>{{ $t('PirateBay.Chapter3.checkSolution.answer_overfit') }}</strong> {{ pair.generatedAnswer }}
      </div>
      <div>
        <strong>{{ $t('PirateBay.Chapter3.checkSolution.answer_expected') }}</strong> {{ pair.expectedAnswer }}
      </div>
      <div>
        <span><strong>{{ $t('PirateBay.Chapter3.checkSolution.evaluation') }}</strong>&nbsp;</span>
        <span v-if="pair.isCorrect">{{ $t('PirateBay.Chapter3.checkSolution.correct') }}&nbsp;<b-icon icon="check-circle-fill" variant="success"></b-icon></span>
        <span v-if="!pair.isCorrect">{{ $t('PirateBay.Chapter3.checkSolution.incorrect') }}&nbsp;<b-icon icon="x-circle-fill" variant="danger"></b-icon></span>
      </div>
      <hr />
    </div>

    <b-alert v-if="solution_is_wrong" show variant="warning">{{ $t('SolutionCode.wrongSolutionAlert') }}</b-alert>
    <b-alert v-if="solution_is_correct" show variant="success">{{ $t('SolutionCode.correctSolutionAlert') }}</b-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entry: "",
      showCheckHeader: false,
      solution_is_correct: false,
      solution_is_wrong: false,
      questionAnswerPairs: [
        { question: this.$t("PirateBay.Chapter3.checkSolution.Question1"), generatedAnswer: null, expectedAnswer: this.$t("PirateBay.Chapter3.checkSolution.ExpectedAnswer1"), isCorrect: null },
        { question: this.$t("PirateBay.Chapter3.checkSolution.Question2"), generatedAnswer: null, expectedAnswer: this.$t("PirateBay.Chapter3.checkSolution.ExpectedAnswer2"), isCorrect: null },
        { question: this.$t("PirateBay.Chapter3.checkSolution.Question3"), generatedAnswer: null, expectedAnswer: this.$t("PirateBay.Chapter3.checkSolution.ExpectedAnswer3"), isCorrect: null },
        { question: this.$t("PirateBay.Chapter3.checkSolution.Question4"), generatedAnswer: null, expectedAnswer: this.$t("PirateBay.Chapter3.checkSolution.ExpectedAnswer4"), isCorrect: null },
      ],
      currentIndex: -1,
      currentQuestion: null,
      allAnswersCorrect: null,
      processedQuestions: [],
      showSpinner: false,
    };
  },

  computed: {
    solutionLabel() {
      return this.$t("SolutionCode.solutionLabel");
    }
  },

  methods: {
    async generateAnswerFromChatGPT(question) {
      try {
        const generatedAnswer = await this.$axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content: this.$t("PirateBay.Chapter3.checkSolution.Question1") +
                ", " + this.$t("PirateBay.Chapter3.checkSolution.Question2") + ", " + this.$t("PirateBay.Chapter3.checkSolution.Question3") + ", "+
                this.$t("PirateBay.Chapter3.checkSolution.Question4")
              },
              {
                role: "user",
                content: this.entry + question
              }
            ]
          },
          {
            headers: {
                  "Authorization": "Bearer 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", // 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
                  "Content-Type": "application/json"
                }
          }
        );

        console.log(generatedAnswer.data.choices[0].message.content)

        return generatedAnswer.data.choices[0].message.content

      } catch (error) {
        console.error("Error sending message:", error);
        console.log("Did you set the openai key?")
      }
    },

    checkAnswer(question) {
      if (question && question.generatedAnswer && question.expectedAnswer) {
        const expectedAnswer = question.expectedAnswer.toLowerCase().trim();
        const generatedAnswer = question.generatedAnswer.toLowerCase().trim();
        question.isCorrect = generatedAnswer.includes(expectedAnswer);
      }
    },

    async loopQuestions() {
      // Clear processed questions
      this.processedQuestions = []

      // Loop through questions to get ChatGPT's answers
      for (let i = 0; i < this.questionAnswerPairs.length; i++) {
        let pair = Object.assign({}, this.questionAnswerPairs[i]); // Needs to be a deep copy!
        if (pair && pair.generatedAnswer === null) {
          try {
            const generatedAnswer = await this.generateAnswerFromChatGPT(pair.question);
            pair.generatedAnswer = generatedAnswer;
            this.checkAnswer(pair);
          } catch (error) {
            console.error("Error generating answer:", error);
            pair.generatedAnswer = "Failed to generate answer";
          }

          // Add to the object that is shown
          this.processedQuestions.push(pair);

          // Stop if incorrect
          if (!pair.isCorrect) {
            break;
          }

        }


      }
    },

    checkAllAnswersCorrect() {
      // this.allAnswersCorrect = this.questionAnswerPairs.every(pair => pair.isCorrect);
      if (this.processedQuestions.every(pair => pair.isCorrect)) {
        this.solution_is_correct = true;
        this.solution_is_wrong = false;

        this.$emit("solution-correct", true);
      } else {
        this.solution_is_correct = false;
        this.solution_is_wrong = true;
      }

      // Logging some results
      console.log(this.solution_is_correct);
      console.log(this.story_content_is_correct);
    },

    onSubmit(event) {
      // Show spinner and check-header
      this.showSpinner = true;
      this.showCheckHeader = true;

      event.preventDefault()

      // Reset result banner
      this.solution_is_correct = false;
      this.solution_is_wrong = false;

      this.loopQuestions().then(() => {
        this.checkAllAnswersCorrect()

        // Hide spinner
        this.showSpinner = false;
      })


    }
  },
}
</script>