<template>
  <div id="app" :class="routeClass">
    <b-container class="background">

      <!-- Main Content -->
      <router-view class="view"></router-view>

      <!-- Contact button -->
      <contact-modal></contact-modal>

      <!-- Home Button -->
      <home-button></home-button>

      <!-- Locale switcher -->
      <locale-switcher></locale-switcher>

    </b-container>

    <div id="content"></div>

    <!-- Footer -->
    <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50">
      <div class="container">
        <b-row align-v="center">
          <b-col cols="10">
            <small>{{ $t('App.footer.info') }}: <a href="https://www.ai-adventure.com/" target="blank">ai-adventure.com</a></small>
            <br>
            <small><router-link to="/admin">Admin</router-link></small>
            <br>
            <small><small>Version: d80d60bb</small></small>
          </b-col>
          <b-col align="center">
            <small>{{ $t('App.footer.presented') }}</small>
            <a href="https://www.alexanderthamm.com/" target="blank"><img style="width:100px;" src="@/assets/reusables/AT_Logo.svg" alt=""></a>
          </b-col>
        </b-row>

      </div>
    </footer>

  </div>
</template>

<script>
import ContactModal from './components/ContactModal.vue';
import LocaleSwitcher from './components/LocaleSwitcher.vue';
import HomeButton from './components/HomeButton.vue';

export default {
  name: 'App',
  components: {
    ContactModal,
    LocaleSwitcher,
    HomeButton,
  },
  data() {
    return {
      routeClass: "Home",
    }
  },

  watch: {
    '$route': function (newRoute) {
      console.log("newRoute:", newRoute)
      // Update the routeClass when the route changes
      this.routeClass = newRoute.name;
    }
  },

  created() {
    this.routeClass = this.$route.name || 'Home';
  },
}
</script>

<style lang="scss">
@import "@/_variables.scss";

// Fonts
@font-face {
    font-family: Papyrus;
    src: url('@/assets/fonts/papyrus.ttf');
}

// Footer
#content {
  flex:1;
}

// Home
.b-home {
  color: $home-primary-text !important;
  background-color: $home-primary !important;
  border: none !important;
}

// Secret Society
.secret-society-button {
  color: $secret-society-primary-text !important;
  background-color: $secret-society-primary !important;
  border: none !important;
}
.secret-society-background {
  background-color: $secret-society-primary-background !important;
}

// Tresure Hunt
.treasure-hunt-button {
  color: $treasure-hunt-primary-text !important;
  background-color: $treasure-hunt-primary !important;
  border: none !important;
}
.treasure-hunt-background {
  background-color: $treasure-hunt-primary-background !important;
}

.treasure-hunt-highlight {
  background-color: rgba(255, 255, 255, .5);
  border-left: solid 10px $treasure-hunt-primary;
  padding: 15px;
  margin-left: 20px;
  margin-bottom: 15px;
  max-width: max-content;
}

// Pirate Bay
.pirate-bay-button {
  color: $pirate-bay-primary-text !important;
  background-color: $pirate-bay-primary !important;
  border: none !important;
}
.pirate-bay-background {
  background-color: $pirate-bay-primary-background !important;
}

.pirate-bay-highlight {
  background-color: rgba(255, 255, 255, .5);
  border-left: solid 10px $pirate-bay-primary;
  padding: 15px;
  margin-left: 20px;
  margin-bottom: 15px;
  max-width: max-content;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

#app,
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:flex;
  flex-direction:column;
}

.background {
  background-color: rgba(255, 255, 255, .85);
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 50px;
}

#app {
  overflow: auto;
  position: relative;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Home, .AdminArea {
  background: url(@/assets/backgrounds/abstract-ai.jpg) no-repeat center center fixed;
}

.SecretSociety {
  background: url(@/assets/backgrounds/secret-society.jpg) no-repeat center center fixed;
}

.TreasureHunt {
  background: url(@/assets/backgrounds/treasure-hunt.jpg) no-repeat center center fixed;
}

.PirateBay {
  background: url(@/assets/backgrounds/pirate-bay.jpg) no-repeat center center fixed;
}

/* .highlight {
  background-color: rgba(255, 255, 255, .5);
  border-left: solid chocolate 10px;
  padding: 15px;
  margin-left: 20px;
  margin-bottom: 15px;
  max-width: max-content;
} */

.monospace {
  font-family: 'Courier New', Courier, monospace;
}

.collapse-white {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 15px;
}

.custom-button {
  background-color: Chocolate !important;
  border: none !important;
}
</style>

<style scoped>
#admin-button {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

#commit-sha {
  position: fixed;
  bottom: 5px;
  left: 5px;
  color: white;
  font-size: 0.8em;
}
</style>
